import RealisationLine from "../line/RealisationLine";
import Loader from "../loader/Loader";
import style from "./MayorPopup.module.css";

function MayorPopup({ data, isLoading }) {
  return !isLoading ? (
    <div className={style.mayorPop}>
      <div className={style.mayorImage}>
        <img src={data?.img} alt={data?.fullname} />
      </div>
      <div className={style.mayorDetails}>
        <h3>
          {data.fullname}
          <span title={data.politicalParty} className={`party ${style.party}`}>{data.politicalPartyAc}</span>
        </h3>
        <span>{data.municipality}</span>
        <RealisationLine data={data.realisation} showTotal={true} />
      </div>
    </div>
  ) : (
    <div className={style.mayorPopLoading}>
      <Loader defaultLoader={false} />
    </div>
  );
}
export default MayorPopup;
