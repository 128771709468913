import { BsFillCaretDownFill } from "react-icons/bs";
import style from "./Dropdown.module.css";
import { MdAccessTime, MdArrowDropDown } from "react-icons/md";

function Dropdown({ data, dropdownHeader = false, changeField, active, title }) {
  const changeDropdown = (value) => {
    changeField(value);
  };

  return (
    <div className={`${style.dropdown} ${dropdownHeader && style.fullWidth}`}>
      {!dropdownHeader ? (
        <p>
          {active} <BsFillCaretDownFill size={10} color="#00174d" />{" "}
        </p>
      ) : (
        <div className={style.dropdownHeader}>
          <div>
            <MdAccessTime size={22} />
            <p>{title}</p>
          </div>
          <MdArrowDropDown />
        </div>
      )}
      <ul className={style.dropdownBody}>
        {data?.map((i, j) => {
          return (
            <li key={j} onClick={() => changeDropdown(i)}>
              {i.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
export default Dropdown;
