import { useEffect, useState } from "react";
import Dropdown from "../../components/dropdown/Dropdown";
import { client } from "../../utils/config";

function MandateDropdown({ dropdownHeader = false, changeMandateYear }) {
  const [mandateYear, setMandateYear] = useState([]);
  const [active, setActive] = useState("");

  useEffect(() => {
    client.get("getMandates").then((response) => {
      setMandateYear(response.data);
      setActive(response.data[response.data.length - 1].name);
    });
  }, []);

  const onClick = (value) => {
    changeMandateYear({ selectedMandate: value.id });
    setActive(value.name);

  };

  return (
    <>
      {mandateYear && (
        <Dropdown
          data={mandateYear}
          dropdownHeader={dropdownHeader}
          changeField={onClick}
          active={active}
          title = {"Zgjedh Mandatin"}
        />
      )}
    </>
  );
}
export default MandateDropdown;
