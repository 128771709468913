import { useState, useEffect } from "react";
import MobileHeader from "./MobileHeader";
import DesktopHeader from "./DesktopHeader";

function HeaderHandler() {
  const [width, setWidth] = useState(window.innerWidth);
  const [openCategories, setOpenCategories] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const isMobile = width <= 1000;

  const menu = [
    {
      title: "Premtime",
      url: "/",
    },
    {
      title: "Raporte",
      url: "reports",
    },
    {
      title: "Infografika",
      url: "infographics",
    }
  ];

  return (
    <>
      {isMobile ? (
        <MobileHeader menu={menu} setOpenCategories={setOpenCategories} />
      ) : (
        <DesktopHeader menu={menu} setOpenCategories={setOpenCategories} />
      )}
    </>
  );
}

export default HeaderHandler;
