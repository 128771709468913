import { AnimationOnScroll } from "react-animation-on-scroll";
import RealisationLine from "../line/RealisationLine";
import style from "./PromiseField.module.css";

function PromiseField({ title, data }) {
  return (
    <>
      <AnimationOnScroll animateIn="animate__zoomIn">
        <div className={`${style.promiseField}`}>
          {data?.image && <img src={data?.image} alt="Kategoritë" />}
          <div>
            <h3>{title}</h3>
            <p>{data?.total}</p>
            <div className={style.fieldRealisation}>
              <RealisationLine data={data.percentage} showLegend={false} />
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </>
  );
}
export default PromiseField;
