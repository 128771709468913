import { Link } from "react-router-dom";
import Grid from "../../layouts/grid/Grid";
import style from "./Report.module.css";
import Search from "../../components/search/Search";
import { useEffect, useState } from "react";
import { client } from "../../utils/config";
import ContentLoad from "../../components/loader/ContentLoader";
import Loader from "../../components/loader/Loader";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Dropdown from "../../components/dropdown/Dropdown";

function Reports() {
  const [data, setData] = useState({
    years: [],
    municipality: [],
    documents: [],
    isLoading: true,
    selectedYear: -1,
    selectedMunicipality: {
      id: -1,
    },
  });

  const changeDataProps = (data) => {
    setData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  useEffect(() => {
    getInfographicsData();
    getMunicipalities();
  }, []);

  useEffect(() => {
    getInfographicsData();
  }, [data.selectedYear, data.selectedMunicipality]);

  const getMunicipalities = () => {
    client.get(`getMunicipalities/`).then((response) => {
      changeDataProps({
        municipality: response.data,
      });
    });
  };

  const getInfographicsData = () => {
    changeDataProps({
      isLoading: true,
    });
    client
      .get(`getReports/${data.selectedYear}/${data.selectedMunicipality.id}`)
      .then((response) => {
        console.log("RESPONSE", response);
        if (data.selectedYear === -1) {
          changeDataProps({
            years: response.data.year,
            documents: response.data.data,
            isLoading: false,
          });
        } else {
          changeDataProps({
            documents: response.data.data,
            isLoading: false,
          });
        }
      });
  };

  const onClick = (i) => {
    changeDataProps({
      selectedMunicipality: { id: i.municipalityId, title: i.municipalityName },
    });
  };

  const changeYear = (i) => {
    if (i.year == data.selectedYear) {
      changeDataProps({ selectedYear: -1 });
    } else changeDataProps({ selectedYear: i.year });
  };

  return (
    <>
      {data.isLoading && <Loader />}
      <div className="midContainer">
        <div className={style.municipalityDropwodn}>
          <Dropdown
            data={data.municipality}
            changeField={onClick}
            active={
              data?.selectedMunicipality?.id > -1
                ? data.selectedMunicipality?.title
                : "Filtro sipas komunës"
            }
            title={"Mandati"}
          />
        </div>
        <div className={style.reportWrapper}>
          <ul>
            {data.years &&
              data?.years.map((i, j) => {
                return (
                  <li
                    className={`${
                      i.year === data.selectedYear && style.active
                    }`}
                    key={j}
                    onClick={() => changeYear(i)}
                  >
                    {i.year}
                  </li>
                );
              })}
          </ul>
          <>
            {data?.documents.length > 0 ? <Grid no={5}>
              { data?.documents.map((i, j) => {
                return (
                  <AnimationOnScroll
                    animateIn="animate__fadeInDown"
                    delay={j + 10}
                    className={style.report}
                    key={j}
                  >
                    <a
                      href={i.path}
                      target="_blank"
                      to=""
                      className={style.report}
                    >
                      <div>
                        <img src={i.img} alt="raportet" loading="lazy" />
                      </div>
                      <h1>{i.title}</h1>
                    </a>
                  </AnimationOnScroll>
                );
              })}
            </Grid> : <p>Nuk u gjet asnjë raport!</p>}
          </>
        </div>
      </div>
    </>
  );
}
export default Reports;
