import { useEffect, useState } from "react";
import MayorPopup from "../popup/MayorPopup";
import style from "./Map.module.css";
import { Link } from "react-router-dom";
import { FaHistory } from "react-icons/fa";
import { generatePath } from "../../utils/helper";
import MayorPopupMobile from "../popup/MayorPopupMobile";

function Map({ handleChange, isLoading, data }) {
  const [municipalityId, setMunicipalityId] = useState(null);
  const [hoveredPath, setHoveredPath] = useState(null);
  const [position, setPosition] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [hoveredCoordinates, setHoveredCoordinates] = useState({ x: 0, y: 0 });
  const [loadingPopup, setLoadingPopup] = useState(false);

  const handleClick = (id) => {
    // const clickedElement = document.getElementById(id);
    // const clickedElementRect = clickedElement.getBoundingClientRect();
    // setPosition({
    //   x: clickedElementRect.left + window.pageXOffset,
    //   y: clickedElementRect.top + window.pageYOffset,
    // });
    // //Set coordinates
    // setMunicipalityId(id);
  };

  useEffect(() => {
    if (municipalityId) {
      //Show details
      handleChange(municipalityId);
    }
  }, [municipalityId]);

  const municipalityInfo = [
    {
      name: "Hani i Elezit",
      path: "M298.85,541.63L298.85,541.63c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C334.75,529.53,313.25,529.13,298.85,541.63z",
      shadow:
        "M298.85,539.88L298.85,539.88c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C334.75,527.78,313.25,527.38,298.85,539.88z",
      text: "matrix(1 0 0 1 304.7021 566.6876)",
      id: "31",
    },
    {
      name: "Novobërdë",
      path: "M441.75,217.93L441.75,217.93c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C477.65,205.73,456.15,205.43,441.75,217.93z",
      text: "matrix(1 0 0 1 429.4035 247.023)",
      id: "14",
      shadow:
        "M441.75,215.03L441.75,215.03c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C477.65,202.83,456.15,202.53,441.75,215.03z",
    },
    {
      name: "Vushtrri",
      path: "M298.85,217.93L298.85,217.93c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C334.75,205.73,313.25,205.43,298.85,217.93z",
      text: "matrix(1 0 0 1 297.0565 247.0508)",
      id: "27",
      shadow:
        "M298.85,215.03L298.85,215.03c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C334.75,202.83,313.25,202.53,298.85,215.03z",
    },
    {
      name: "Lipjan",
      path: "M298.85,381.23L298.85,381.23c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C334.75,369.13,313.25,368.73,298.85,381.23z",
      text: "matrix(1 0 0 1 303.3261 410.3306)",
      id: "13",
      shadow:
        "M298.85,378.33L298.85,378.33c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C334.75,366.23,313.25,365.83,298.85,378.33z",
    },
    {
      name: "Kamenicë",
      path: "M513.25,258.73L513.25,258.73c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C549.15,246.63,527.65,246.23,513.25,258.73z",
      text: "matrix(1 0 0 1 504.217 287.8638)",
      id: "10",
      shadow:
        "M513.25,255.83L513.25,255.83c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C549.15,243.73,527.65,243.33,513.25,255.83z",
    },
    {
      name: "Obiliq",
      path: "M370.35,258.73L370.35,258.73c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C406.15,246.63,384.75,246.23,370.35,258.73z",
      text: "matrix(1 0 0 1 374.6789 287.8345)",
      id: "15",
      shadow:
        "M370.35,255.83L370.35,255.83c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C406.15,243.73,384.75,243.33,370.35,255.83z",
    },
    {
      name: "Podujevë",
      path: "M298.85,136.33L298.85,136.33c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C334.75,124.13,313.25,123.83,298.85,136.33z",
      text: "matrix(1 0 0 1 291.5732 165.4009)",
      id: "18",
      shadow:
        "M298.85,133.43L298.85,133.43c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C334.75,121.23,313.25,120.93,298.85,133.43z",
    },
    {
      name: "Prishtinë",
      path: "M370.35,177.13L370.35,177.13c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C406.15,164.93,384.75,164.63,370.35,177.13z",
      text: "matrix(1 0 0 1 365.6332 206.2139)",
      id: "19",
      shadow:
        "M370.35,174.23L370.35,174.23c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C406.15,162.03,384.75,161.73,370.35,174.23z",
    },
    {
      name: "Shtërpcë",
      path: "M298.85,462.93L298.85,462.93c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C334.75,450.73,313.25,450.43,298.85,462.93z",
      text: "matrix(1 0 0 1 292.8173 492.1331)",
      id: "23",
      shadow:
        "M298.85,460.03L298.85,460.03c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C334.75,447.83,313.25,447.53,298.85,460.03z",
    },
    {
      name: "Zubin Potok",
      path: "M155.95,136.33L155.95,136.33c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C191.85,124.13,170.35,123.83,155.95,136.33z",
      text: "matrix(1 0 0 1 162.8454 158.9053)",
      id: "28",
      shadow:
        "M155.95,133.43L155.95,133.43c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C191.85,121.23,170.35,120.93,155.95,133.43z",
    },
    {
      name: "Malishevë",
      path: "M155.95,299.63L155.95,299.63c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C191.85,287.43,170.35,287.13,155.95,299.63z",
      text: "matrix(1 0 0 1 147.4933 328.7083)",
      id: "30",
      shadow:
        "M155.95,296.73L155.95,296.73c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C191.85,284.53,170.35,284.23,155.95,296.73z",
    },
    {
      name: "Skënderaj",
      path: "M155.95,217.93L155.95,217.93c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C191.85,205.73,170.35,205.43,155.95,217.93z",
      text: "matrix(1 0 0 1 147.3817 247.023)",
      id: "21",
      shadow:
        "M155.95,215.03L155.95,215.03c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C191.85,202.83,170.35,202.53,155.95,215.03z",
    },
    {
      name: "Mamushë",
      path: "M155.95,462.93L155.95,462.93c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C191.85,450.73,170.35,450.43,155.95,462.93z",
      text: "matrix(1 0 0 1 147.8319 492.0164)",
      id: "32",
      shadow:
        "M155.95,460.03L155.95,460.03c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C191.85,447.83,170.35,447.53,155.95,460.03z",
    },
    {
      name: "Rahovec",
      path: "M155.95,381.23L155.95,381.23c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C191.85,369.13,170.35,368.73,155.95,381.23z",
      text: "matrix(1 0 0 1 150.664 410.3616)",
      id: "16",
      shadow:
        "M155.95,378.33L155.95,378.33c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C191.85,366.23,170.35,365.83,155.95,378.33z",
    },
    {
      name: "Ferizaj",
      path: "M370.35,422.03L370.35,422.03c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C406.15,409.93,384.75,409.63,370.35,422.03z",
      text: "matrix(1 0 0 1 373.4337 451.1729)",
      id: "25",
      shadow:
        "M370.35,419.13L370.35,419.13c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C406.15,407.03,384.75,406.73,370.35,419.13z",
    },
    {
      name: "Kaçanik",
      path: "M370.35,503.73L370.35,503.73c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C406.15,491.53,384.75,491.23,370.35,503.73z",
      text: "matrix(1 0 0 1 368.3087 532.7967)",
      id: "7",
      shadow:
        "M370.35,500.83L370.35,500.83c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C406.15,488.63,384.75,488.33,370.35,500.83z",
    },
    {
      name: "Pejë",
      path: "M13.05,217.93L13.05,217.93c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C48.85,205.73,27.45,205.43,13.05,217.93z",
      text: "matrix(1 0 0 1 22.3212 247.0508)",
      id: "17",
      shadow:
        "M13.05,215.03L13.05,215.03c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C48.85,202.83,27.45,202.53,13.05,215.03z",
    },
    {
      name: "Viti",
      path: "M441.75,462.93L441.75,462.93c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C477.65,450.73,456.15,450.43,441.75,462.93z",
      text: "matrix(1 0 0 1 454.5783 491.9632)",
      id: "26",
      shadow:
        "M441.75,460.03L441.75,460.03c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C477.65,447.83,456.15,447.53,441.75,460.03z",
    },
    {
      name: "Gjilan",
      path: "M441.75,299.63L441.75,299.63c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C477.65,287.43,456.15,287.13,441.75,299.63z",
      text: "matrix(1 0 0 1 447.507 328.6766)",
      id: "4",
      shadow:
        "M441.75,296.73L441.75,296.73c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C477.65,284.53,456.15,284.23,441.75,296.73z",
    },
    {
      name: "Kllokot",
      path: "M441.75,381.23L441.75,381.23c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C477.65,369.13,456.15,368.73,441.75,381.23z",
      text: "matrix(1 0 0 1 442.6769 410.3618)",
      id: "34",
      shadow:
        "M441.75,378.33L441.75,378.33c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C477.65,366.23,456.15,365.83,441.75,378.33z",
    },
    {
      name: "Deçan",
      path: "M13.05,299.63L13.05,299.63c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C48.85,287.43,27.45,287.13,13.05,299.63z",
      text: "matrix(1 0 0 1 15.5878 328.6766)",
      id: "1",
      shadow:
        "M13.05,296.73L13.05,296.73c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C48.85,284.53,27.45,284.23,13.05,296.73z",
    },
    {
      name: "Gjakovë",
      path: "M84.45,422.03L84.45,422.03c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C120.35,409.93,98.85,409.63,84.45,422.03z",
      text: "matrix(1 0 0 1 81.1828 451.1429)",
      id: "2",
      shadow:
        "M84.45,419.13L84.45,419.13c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C120.35,407.03,98.85,406.73,84.45,419.13z",
    },
    {
      name: "Junik",
      path: "M84.45,340.43L84.45,340.43c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C120.35,328.23,98.85,327.93,84.45,340.43z",
      text: "matrix(1 0 0 1 91.8117 369.5213)",
      id: "33",
      shadow:
        "M84.45,337.53L84.45,337.53c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C120.35,325.33,98.85,325.03,84.45,337.53z",
    },
    {
      name: "Klinë",
      path: "M84.45,258.73L84.45,258.73c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C120.35,246.63,98.85,246.23,84.45,258.73z",
      text: "matrix(1 0 0 1 92.0607 287.8345)",
      id: "8",
      shadow:
        "M84.45,255.83L84.45,255.83c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C120.35,243.73,98.85,243.33,84.45,255.83z",
    },
    {
      name: "Istog",
      path: "M84.45,177.13L84.45,177.13c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C120.35,164.93,98.85,164.63,84.45,177.13z",
      text: "matrix(1 0 0 1 91.506 206.2134)",
      id: "6",
      shadow:
        "M84.45,174.23L84.45,174.23c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C120.35,162.03,98.85,161.73,84.45,174.23z",
    },
    {
      name: "Mitrovicë e Veriut",
      path: "M298.85,54.63L298.85,54.63c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C334.75,42.43,313.25,42.13,298.85,54.63z",
      text: "matrix(1 0 0 1 293.3114 77.2505)",
      id: "38",
      shadow:
        "M298.85,51.73L298.85,51.73c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C334.75,39.53,313.25,39.23,298.85,51.73z",
    },
    {
      name: "Ranillug",
      path: "M513.25,340.43L513.25,340.43c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C549.15,328.23,527.65,327.93,513.25,340.43z",
      text: "matrix(1 0 0 1 511.2487 369.4886)",
      id: "36",
      shadow:
        "M513.25,337.53L513.25,337.53c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C549.15,325.33,527.65,325.03,513.25,337.53z",
    },
    {
      name: "Partesh",
      path: "M513.25,422.03L513.25,422.03c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C549.15,409.93,527.65,409.63,513.25,422.03z",
      text: "matrix(1 0 0 1 512.2614 451.1426)",
      id: "37",
      shadow:
        "M513.25,419.13L513.25,419.13c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C549.15,407.03,527.65,406.73,513.25,419.13z",
    },
    {
      name: "Leposaviq",
      path: "M227.35,13.83L227.35,13.83c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C263.25,1.63,241.85,1.33,227.35,13.83z",
      text: "matrix(1 0 0 1 217.6666 42.8726)",
      id: "12",
      shadow:
        "M227.35,10.93L227.35,10.93c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C263.25-1.27,241.85-1.57,227.35,10.93z",
    },
    {
      name: "Mitrovicë",
      path: "M227.35,177.13L227.35,177.13c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C263.25,164.93,241.85,164.63,227.35,177.13z",
      text: "matrix(1 0 0 1 220.9071 206.1807)",
      id: "11",
      shadow:
        "M227.35,174.23L227.35,174.23c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C263.25,162.03,241.85,161.73,227.35,174.23z",
    },
    {
      name: "Suharekë",
      path: "M227.35,340.43L227.35,340.43c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C263.25,328.23,241.85,327.93,227.35,340.43z",
      text: "matrix(1 0 0 1 220.6906 369.5696)",
      id: "24",
      shadow:
        "M227.35,337.53L227.35,337.53c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C263.25,325.33,241.85,325.03,227.35,337.53z",
    },
    {
      name: "Graçanicë",
      path: "M370.35,340.43L370.35,340.43c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C406.15,328.23,384.75,327.93,370.35,340.43z",
      text: "matrix(1 0 0 1 361.3141 369.4886)",
      id: "35",
      shadow:
        "M370.35,337.53L370.35,337.53c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C406.15,325.33,384.75,325.03,370.35,337.53z",
    },
    {
      name: "Fushë Kosovë",
      path: "M298.85,299.63L298.85,299.63c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C334.75,287.43,313.25,287.13,298.85,299.63z",
      text: "matrix(1 0 0 1 303.6625 320.2647)",
      id: "9",
      shadow:
        "M298.85,296.73L298.85,296.73c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C334.75,284.53,313.25,284.23,298.85,296.73z",
    },
    {
      name: "Drenas",
      path: "M227.35,258.73L227.35,258.73c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C263.25,246.63,241.85,246.23,227.35,258.73z",
      text: "matrix(1 0 0 1 228.1481 287.8633)",
      id: "3",
      shadow:
        "M227.35,255.83L227.35,255.83c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C263.25,243.73,241.85,243.33,227.35,255.83z",
    },
    {
      name: "Shtime",
      path: "M227.35,422.03L227.35,422.03c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C263.25,409.93,241.85,409.63,227.35,422.03z",
      text: "matrix(1 0 0 1 228.5426 450.8485)",
      id: "22",
      shadow:
        "M227.35,419.13L227.35,419.13c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C263.25,407.03,241.85,406.73,227.35,419.13z",
    },
    {
      name: "Dragash",
      path: "M227.35,585.33L227.35,585.33c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C263.25,573.23,241.85,572.93,227.35,585.33z",
      text: "matrix(1 0 0 1 223.9606 614.4507)",
      id: "5",
      shadow:
        "M227.35,582.43L227.35,582.43c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C263.25,570.33,241.85,570.03,227.35,582.43z",
    },
    {
      name: "Prizren",
      path: "M227.35,503.73L227.35,503.73c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C263.25,491.53,241.85,491.23,227.35,503.73z",
      text: "matrix(1 0 0 1 228.9391 532.8494)",
      id: "20",
      shadow:
        "M227.35,500.83L227.35,500.83c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C263.25,488.63,241.85,488.33,227.35,500.83z",
    },
    {
      name: "Zvecan",
      path: "M227.35,95.43L227.35,95.43c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C263.25,83.23,241.85,82.93,227.35,95.43z",
      text: "matrix(1 0 0 1 226.7096 124.5269)",
      id: "29",
      shadow:
        "M227.35,92.53L227.35,92.53c-26.2,22.7-10.3,65.7,24.3,65.9l0,0c34.4,0.2,50.9-42,25.6-65.2l0,0 C263.25,80.33,241.85,80.03,227.35,92.53z",
    },
  ];

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const handleHover = (event, pathId) => {
    if (pathId !== hoveredPath) {
      setLoadingPopup(true);
      const clickedElement = document.getElementById(pathId);
      const clickedElementRect = clickedElement.getBoundingClientRect();
      setHoveredPath(pathId);
      setHoveredCoordinates({
        x: clickedElementRect.left + window.pageXOffset,
        y: clickedElementRect.top + window.pageYOffset,
      });
      setTimeout(() => setLoadingPopup(false), 350);
    }
  };

  const handleLeave = () => {
    setHoveredPath(null);
  };

  return (
    <div onMouseLeave={handleLeave}>
      <div className={style.mapContainer}>
        {data.lastUpdateDate && (
          <div className={style.mapRightInfo}>
            <div>
              <FaHistory size={25} color="#c7d7e8" />
            </div>
            <div>
              <p>
                Përditësimi i fundit më <span>{data.lastUpdateDate}</span>
              </p>
              <p>
              Përditësimi i ardhshëm më <span>{data.nextUpdateDate}</span>
              </p>
            </div>
          </div>
        )}
        <div className={style.mapWrapper}>
          <svg
            version="1.1"
            id="komunat"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 577 652"
            style={{ enableBackground: "new 0 0 577 652" }}
          >
            {municipalityInfo.map((i, j) => {
              return (
                <g
                  className={style.municipality}
                  onClick={() => handleClick(i.id)}
                  onMouseEnter={(event) => handleHover(event, i.id)}
                  id={i.id}
                  title={i.title}
                  key={j}
                >
                  <path className={style.st1} d={i.path} />
                  <radialGradient
                    id="SVGID_1_"
                    cx="224.8451"
                    cy="89.4541"
                    r="514.9446"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.4021" stopColor="#CAD1E4" />
                    <stop offset="1" stopColor="#99A5D1" />
                  </radialGradient>
                  <path
                    className={`${style.st2} partyIdentifier`}
                    d={i.shadow}
                  />
                  <text
                    transform={i.text}
                    className={style.st4 + " " + style.st5 + " " + style.st6}
                  >
                    <tspan
                      x="0"
                      y="0"
                      className={style.st4 + " " + style.st5 + " " + style.st6}
                    >
                      {i.name.split(" ")[0]}
                    </tspan>
                    <tspan
                      x="1.04"
                      y="15.6"
                      className={style.st4 + " " + style.st5 + " " + style.st6}
                    >
                      {i?.name.split(" ")[1]}
                    </tspan>
                    <tspan
                      x="10"
                      y="18.6"
                      className={style.st4 + " " + style.st5 + " " + style.st6}
                    >
                      {i?.name.split(" ")[2]}
                    </tspan>
                  </text>
                </g>
              );
            })}
          </svg>
        </div>
      </div>
      {/* {hoveredPath && (
        <Link
          to={generatePath(`promises/5`)}
          className="mayorContainer"
          style={{
            position: "absolute",
            left: hoveredCoordinates.x,
            top: hoveredCoordinates.y,
            zIndex: 999,
          }}
        >
          <MayorPopup data={[]} isLoading={isLoading} />
        </Link>
      )} */}
      {hoveredPath &&
        data?.mayorDetails &&
        data?.mayorDetails?.[hoveredPath] && (
          <Link
            to={generatePath(
              `promises/${data.mayorDetails?.[hoveredPath]?.mayorId}`
            )}
            className="mayorContainer"
            style={{
              position: "absolute",
              left: hoveredCoordinates.x,
              top: hoveredCoordinates.y,
              zIndex: 999,
            }}
          >
            <MayorPopup
              data={data?.mayorDetails[hoveredPath]}
              isLoading={loadingPopup}
            />
          </Link>
        )}
    </div>
  );
}
export default Map;
