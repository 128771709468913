import React, { useState } from "react";
import Hamburger from "hamburger-react";
import style from "./header.module.css";
import { Link, NavLink } from "react-router-dom";
import { BiUser, BiMenu } from "react-icons/bi";
import { generatePath } from "../../utils/helper";

function MobileHeader(props) {
  const [isOpen, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!isOpen);
  };

  return (
    <div id="headerMenu" className={style.headerMobile}>
      <div className={`${style.headerMobileTop} container`}>
        <Link to="/">
          <img
            src={`/images/KomunatLogo.svg`}
            alt="logo"
          />
        </Link>
        <Hamburger
          toggled={isOpen}
          toggle={setOpen}
          size={20}
          direction="right"
        />
      </div>

      <div className={`${style.mobileHeader} ${isOpen ? style.openMenu : ""}`}>
        <div className="main_container">
          <ul>
            {props.menu &&
              props.menu.map((data, index) => (
                <li key={index} className={`${isOpen ? style.fadeIn : ""}`}>
                  <Link
                    to={generatePath(data.url)}
                    onClick={() => setOpen(false)}
                  >
                    {data.title}
                  </Link>
                </li>
              ))}
            <li className={`${isOpen ? style.fadeIn : ""}`}>
              <a
                href="https://institutigap.org/transparenca-buxhetore"
              >
                <span>Transparenca Buxhetore e Komunave</span>
              </a>
            </li>
          </ul>
          <div className={style.mobileNav}></div>
        </div>
      </div>
    </div>
  );
}

export default MobileHeader;
