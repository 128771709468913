import { BsSearch } from "react-icons/bs";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import style from "./Search.module.css";

function Search({ data }) {
  const handleOnSelect = (item) => {
    //Open Mayor Popup
  };

  const formatResult = (item) => {
    return <span className={style.options}>{item.name}</span>;
  };

  return (
    <div className={style.search}>
      <BsSearch size={20} color="#c7d7e8" />
      <div style={{ width: "100%", zIndex: "9999" }}>
        <ReactSearchAutocomplete
          placeholder="Kërko komunën"
          showIcon={false}
          styling={{
            fontSize: "13px",
            fontFamily: "Poppins",
            border: "none",
            borderRadius: "0px",
            boxShadow: "none",
            padding: "0px",
            margin: 0,
            minHeight: "unset",
          }}
          items={data}
          onSelect={handleOnSelect}
          formatResult={formatResult}
        />
      </div>
    </div>
  );
}
export default Search;
