import Grid from "../../layouts/grid/Grid";
import style from "./Card.module.css";
import RealisationLine from "../line/RealisationLine";
import { Link } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { generatePath } from "../../utils/helper";

function WhiteCard({ data, type='mayor' }) {
  return (
    <AnimationOnScroll animateIn="animate__zoomIn">
        <div className={style.imageWrapper}>
          <img src={data?.img} alt="Kryetari" />
        </div>
        <div className={style.body}>
          {data.title && <p>{data.title} </p>}
          <div>
            {data?.fullname && <p>
              {data?.fullname} <span className="party">{data?.politicalParty}</span>
            </p>}
          </div>
          {data?.realisation && (
            <RealisationLine
              data={data?.realisation}
              showLegend={false}
              showTotal={true}
            />
          )}
        </div>
    </AnimationOnScroll>
  );
}
export default WhiteCard;
