import { AnimationOnScroll } from "react-animation-on-scroll";
import style from "./PromiseCard.module.css";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { client } from "../../utils/config";
import { useState } from "react";

function PromiseCard({ data, showDetails, detailsInfo }) {
  const [showSuccess, setShowSuccess] = useState(false);

  let commentSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(4, "Emri dhe Mbiemri duhet të kenë minimum 4 karaktere!")
      .max(50, "Emri dhe Mbiemri duhet të kenë maximum 50 karaktere!")
      .required("Ju lutem plotësoni emrin dhe mbiemrin"),
    comment: Yup.string()
      .min(2, "Komenti duhet të ketë minimum 4 karaktere!")
      .max(250, "Komenti duhet të ketë maximum 50 karaktere!")
      .required("Ju lutem shënoni komentin tuaj!"),
  });


  return (
    <div className={style.card}>
      <div
        className={`${style.promiseCard} ${style[data?.cssClass]}   ${
          detailsInfo.selectedPromise === data.id && style.active
        }`}
        onClick={() => showDetails(data.id)}
      >
        <p>{data.description}</p>
        <div className="spaceBetween">
          <span>{data.realisation}</span>
          {data?.cssClass !== "notStarted" &&
            (detailsInfo.selectedPromise === data.id ? (
              <p></p>
            ) : (
              <span>Shiko më shumë</span>
            ))}
        </div>

        {detailsInfo.selectedPromise === data.id &&
          data?.cssClass !== "notStarted" && (
            <div className={`${style.cardDetails} ${style[data?.cssClass]}`}>
              {detailsInfo?.promiseDetails?.data?.length > 0 ? (
                detailsInfo?.promiseDetails?.data?.map((i, j) => (
                  <p className={style.promiseDetails} key={i.promiseDetailsId}>
                    <div className="spaceBetween">
                      <label className={`${style[i?.cssClass]}`}>
                        {i.titleSingle}
                      </label>
                      <label className={style.date}>
                        {i.dateOfPromiseDetails}
                      </label>
                    </div>

                    <p>{i.description}</p>
                  </p>
                ))
              ) : (
                <p>
                  <label className={`${style[data?.cssClass]}`}>
                    {data.realisation}
                  </label>
                </p>
              )}
              <div className={style.commentSection}>
                {detailsInfo?.promiseDetails?.comment?.length > 0 ? (
                  detailsInfo?.promiseDetails?.comment?.map((i, j) => (
                    <div>
                      <div className="spaceBetween">
                        <span>{i.fullName}</span>
                        <span>{i.dateAdded}</span>
                      </div>
                      <p>{i.comment}</p>
                    </div>
                  ))
                ) : (
                  <p>Nuk është gjetur asnjë koment! </p>
                )}
              </div>
              <div onClick={(e) => e.stopPropagation()}>
                <Formik
                  initialValues={{
                    firstName: "",
                    comment: "",
                  }}
                  validationSchema={commentSchema}
                  onSubmit={(values, { resetForm }) => {
                    client
                      .post("addComment", {
                        name: values.firstName,
                        comment: values.comment,
                        promiseId: data.id,
                      })
                      .then((response) => {
                        resetForm();
                        setShowSuccess(true);
                        setTimeout(function () {
                          setShowSuccess(false);
                        }, 5000);
                      });
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      {showSuccess && (
                        <p className={style.success}>
                          Komenti juaj u dërgua me sukses!
                        </p>
                      )}
                      <Field
                        name="comment"
                        component="textarea"
                        rows="4"
                        placeholder="Komenti juaj"
                      >
                        {" "}
                      </Field>
                      <Field name="firstName" placeholder="Emri dhe Mbiemri" autoComplete="off" />
                      {errors.firstName && touched.firstName ? (
                        <p className={style.error}>{errors.firstName}</p>
                      ) : null}

                      {errors.comment && touched.comment ? (
                        <p className={style.error}>{errors.comment}</p>
                      ) : null}
                      <button type="submit">Dërgo</button>
                    </Form>
                  )}
                </Formik>
                <i className={style.commentsDisclaimer}>{detailsInfo?.promiseDetails?.commentsDisclaimer}</i>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
export default PromiseCard;
