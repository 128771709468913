import React, { useEffect, useMemo, useState } from "react";
import style from "./Homepage.module.css";
import Loader from "../../components/loader/Loader";
import Map from "../../components/map/Map";
import { client } from "../../utils/config.js";
import RealisationLine from "../../components/line/RealisationLine";
import { BsSearch } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import HomepageFilter from "../../layouts/filters/HomepageFilter";
import PromiseFields from "../../layouts/promiseFields/PromiseFields";
import MandateDropdown from "../../layouts/filters/MandateDropdown";
import Text from "../../components/text/Text";
import Grid from "../../layouts/grid/Grid";
import WhiteCard from "../../components/card/WhiteCard";
import { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { generatePath } from "../../utils/helper";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function HomepageNew() {
  const ref = useRef();
  let query = useQuery();

  const [pageProps, setPageProps] = useState({
    mainLoader: false,
    type: "home",
    showMap: true,
    loading: false,
    cities: [],
    partyOptions: [],
  });

  const [dataProps, setDataProps] = useState({
    selectedMunicipality: -1,
    selectedMandate: -1,
    selectedParty: -1,
    selectedPartyName: "",
    promiseDetails: [],
    mayorDetails: [],
    partyMayorsDetails: [],
    updateTime: null,
  });

  const updateUrl = (name, value) => {
    const urlParams = new URLSearchParams(window.location.search);

    // Remove existing parameter with the same name

    urlParams.delete(name);
    if (value !== -1) {
      // Set the new value
      urlParams.set(name, value);
    }

    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState(null, "", newUrl);
  };

  const changeDataProps = (data) => {
    Object.keys(data).forEach((key, value) => {
      if (key === "selectedMandate") {
        updateUrl("mandate", data[key]);
      }

      if (key === "selectedParty") {
        updateUrl("party", data[key]);
      }
    });

    setDataProps((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const changePageProps = (data) => {
    setPageProps((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  useEffect(() => {
    let mandate = -1;
    let party = -1;
    getFilterData();

    //URL PARAMS CHECK
    if (query.get("mandate")) {
      mandate = query.get("mandate");
    }
    if (query.get("party")) {
      party = query.get("party");
    }
    changeDataProps({ selectedMandate: mandate, selectedParty: party });
  }, []);

  useEffect(() => {
    //First Clear All Map Filter
    resetMapColor();
    //Filter map with selectedParty
    if (dataProps.selectedParty >= 0 && dataProps.partyMayorsDetails) {
      if (
        dataProps.partyMayorsDetails.length > 0 &&
        (pageProps.type === "home" || pageProps.type === "filter")
      ) {
        selectedPartyMapColor();
      }
    }
  }, [dataProps.partyMayorsDetails]);

  useEffect(() => {
    getPromiseData();
  }, [dataProps.selectedMandate, dataProps.selectedParty]);

  useEffect(() => {
    getPopUpData();
  }, [dataProps.selectedMandate]);

  const getPopUpData = () => {
    //ON HOVER GET ALL DATA
    client.get(`popUp/${dataProps.selectedMandate}`).then((response) => {
      changeDataProps({
        mayorDetails: response.data,
      });
    });
  };

  const getPromiseData = () => {

    changePageProps({ mainLoader: true });
    client
      .get(
        `getRealisationData/${dataProps.selectedMandate}/${dataProps.selectedParty}`
      )
      .then((response) => {
        setDataProps((prevState) => ({
          ...prevState,
          promiseDetails: response.data.realisationLine,
          partyMayorsDetails: response.data.mayorData,
          lastUpdateDate: response.data.lastUpdateDate,
          nextUpdateDate: response.data.nextUpdateDate
        }));

        setTimeout(() => {
          changePageProps({ mainLoader: false, type: "home" });
        }, 300);
      });
  };

  const getFilterData = () => {
    client.get("filterOptions").then((response) => {
      setPageProps((prevState) => ({
        ...prevState,
        cities: response.data.cities,
        partyOptions: response.data.parties,
      }));
    });
  };

  const getMayorData = (municipalityId) => {
    resetMapColor();
    setPageProps((prevState) => ({
      ...prevState,
      loading: true,
    }));
    client
      .get(
        `getMayorDetailsFromMunicipality/${municipalityId}/${dataProps.selectedMandate}`
      )
      .then((response) => {
        changeDataProps({
          mayorDetails: response.data,
          selectedMunicipality: municipalityId,
          selectedParty: -1,
        });

        setTimeout(() => {
          setPageProps((prevState) => ({
            ...prevState,
            loading: false,
            showSearch: false,
            type: "home",
          }));
        }, 200);
      });
  };

  const resetMapColor = () => {
    const divs = document.querySelectorAll(".partyIdentifier");
    if (divs.length > 0) {
      const fillId = "SVGID_1_";
      divs.forEach((div) => {
        div.style.fill = `url(#${fillId})`;
        div.nextElementSibling
          .querySelector("tspan")
          .style.setProperty("fill", "#19315a", "important");
      });
    }
  };

  const selectedPartyMapColor = () => {
    const divs = document.querySelectorAll(".partyIdentifier");
    if (divs.length > 0) {
      divs.forEach((div) => {
        div.style.fill = `#bec6de`;
        div.nextElementSibling
          .querySelector("tspan")
          .style.setProperty("fill", "#949dbc", "important");
      });

      dataProps.partyMayorsDetails.forEach((i, j) => {
        document
          .querySelector('[id="' + i.municipalityId + '"] .partyIdentifier')
          .style.setProperty("fill", "#00174d", "important");
        document
          .querySelector('[id="' + i.municipalityId + '"] text tspan')
          .style.setProperty("fill", "#fff", "important");
      });
    }
  };

  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      changePageProps({type:"home"});
      // onClick();
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <>
      {pageProps.mainLoader && <Loader />}
      <div className="midContainer ptb50">
        {pageProps.showMap ? (
          <>
            <div className={style.mapInfo}>
              {pageProps.type === "filter" && (
                <HomepageFilter
                  pageProps={pageProps}
                  dataProps={dataProps}
                  changeDataProps={changeDataProps}
                  changePageProps={changePageProps}
                  innerRef={ref}
                />
              )}
              <Text
                customize={dataProps.selectedParty > 0 && "reverse"}
                icon={<BsSearch size={25} color="#c7d7e8" />}
                onClick={() => changePageProps({ type: "filter" })}
                title={
                  dataProps.selectedParty < 0
                    ? "Premtimet e kryetarëve të komunave"
                    : "Premtimet e kryetarëve të"
                }
                desc={
                  dataProps.selectedParty < 0
                    ? "me anë të të cilave synojmë të mbajmë përgjegjësi për  premtimet e dhëna nga kryetarët e zgjedhur të komunave."
                    : dataProps.selectedPartyName
                }
              />
            </div>
            <Map
              handleChange={getMayorData}
              data={dataProps}
              isLoading={pageProps.loading}
            />
          </>
        ) : (
          <PromiseFields
            showMap={() => changePageProps({ showMap: true, type: "home" })}
            dataProps={dataProps}
            party={pageProps?.partyOptions}
            changeDataProps={changeDataProps}
          />
        )}

        <div className={style.spaceBetween}>
          <div>
            <span>Mandati</span>
            <MandateDropdown changeMandateYear={changeDataProps} />
          </div>
          <div>
            <FacebookShareButton
              url={"https://komunat.institutigap.org/"}
              quote={"TEST"}
              className="Demo__some-network__share-button"
              style={{margin: "5px"}}
              media = {"https://hips.hearstapps.com/hmg-prod/images/close-up-of-tulips-blooming-in-field-royalty-free-image-1584131603.jpg?crop=0.630xw:1.00xh;0.186xw,0&resize=1200:*"}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton
              url={"https://komunat.institutigap.org/"}
              title={"Premtimet e kryetarëve të komunave"}
              className="Demo__some-network__share-button"
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>
        </div>
        <RealisationLine data={dataProps.promiseDetails} showLegend={true} />
        {dataProps.partyMayorsDetails &&
          dataProps.partyMayorsDetails.length > 0 && (
            <div className="ptb50">
              <Grid no={"2"}>
                {dataProps.partyMayorsDetails.map((i, j) => {
                  return (
                    <Link
                      to={generatePath(`promises/${i?.mayorId}`)}
                      className={style.card}
                    >
                      <WhiteCard data={i} key={j} />
                    </Link>
                  );
                })}
              </Grid>
            </div>
          )}
        <div className={style.projectDesc}>
          <h1>Rreth projektit: </h1>
          <p>
            “Letërnjoftimi i Komunave” është projekt i Institutit GAP i cili ka
            filluar në vitin 2009, përmes të cilit GAP ka kontribuar në ofrimin
            e të dhënave dhe rekomandimeve që kanë ndikuar në forcimin dhe
            përmirësimin e politikave publike në nivel lokal. Përgjatë këtyre
            viteve, GAP ka qenë dhe vazhdon të mbetet adresa kryesore e të
            dhënave në fushën e qeverisjes lokale. Letërnjoftimi i Komunave ka
            dy komponente: Monitorimi i premtimeve - me anë të të cilave synojmë
            t’i mbajmë përgjegjës kryetarët e zgjedhur të komunave për premtimet
            e dhëna. Të dhënat dhe vlerësimet e premtimeve përditësohen një herë
            në vit. Kjo bëhet edhe përmes bashkëpunimit me organizatat lokale.
            Raportet e Politikave – përmes të cilave raportojmë për qeverisjen
            në nivel lokal, duke rekomanduar edhe për hapa konkret që duhet
            ndërmarrë në drejtim të përmirësimit të politikave të qeverisjes. ​
            Platforma online "Letërnjoftimi i Komunave" është realizuar me
            përkrahjen e National Endowment for Democracy (NED):{" "}
          </p>
          <div>
            <img
              src={`/images/NED.png`}
              alt="NED Logo"
              loading="lazy"
              width="180"
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default HomepageNew;
