import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/homepage/Homepage";
import Mayors from "../pages/mayors/Mayors";
import Promises from "../pages/promises/Promises";
import Reports from "../pages/reports/Reports";
import Infographics from "../pages/infographic/Infographics";
import PromisesDetails from "../pages/promises/PromisesDetails";
import Homepage from "../pages/homepage/Homepage";
import ReactGA from 'react-ga';
import { useEffect } from "react";

function AllRoutes() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Routes>
      <Route path={`/`} element={<Homepage />} />
      <Route path={`/mayors`} element={<Mayors />} />
      <Route path={`/reports`} element={<Reports />} />
      <Route path={`/infographics`} element={<Infographics />} />
      <Route path={`/promises/:id`} element={<PromisesDetails />} />
      <Route path={`/*`} element={<Homepage />} />

    </Routes>
  );
}
export default AllRoutes;
