import Search from "../../components/search/Search";
import style from "./HomepageFilter.module.css";
import MandateDropdown from "./MandateDropdown";

function HomepageFilter({
  pageProps,
  dataProps,
  changeDataProps,
  changePageProps,
  innerRef
}) {
  

  return (
    <div className={style.homepageFilter} ref={innerRef}>
      {/* <div className={style.filterWrapper}>
        <Search data={pageProps.cities} />
      </div> */}
      <div className={`${style.filterWrapperPadding} flex`}>
        <MandateDropdown
          dropdownHeader={true}
          changeMandateYear={changeDataProps}
        />
      </div>
      <div className={`${style.filterWrapperPadding} flex`}>
        <div className={style.filterToggle}>
          <span
            className={style.active}
            onClick={() => changePageProps({ showMap: true, type: "home" })}
          >
            <img
              src={`/images/icons/map.svg`}
              alt="icons"
            />
          </span>
          <span
            onClick={() =>
              changePageProps({ showMap: false, type: "categories" })
            }
          >
            <img
              src={`/images/icons/box.svg`}
              alt="icons"
            />
          </span>
        </div>
        <span>Shfaq sektorët</span>
      </div>
      <div className={style.filterWrapperPadding}>
        <span>Shfaq komunat në bazë të partisë</span>
        <div className={style.municipality}>
          {pageProps?.partyOptions.map((i, j) => {
            return (
              <div
                className={`party ${
                  i.politicalPartyId === dataProps.selectedParty && style.active
                }`}
                key={j}
                onClick={() =>
                  changeDataProps({
                    selectedParty:
                      i.politicalPartyId === dataProps.selectedParty
                        ? -1
                        : i.politicalPartyId,
                    selectedPartyName: i.name,
                    selectedMunicipality:-1
                  })
                }
              >
                {i.name}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default HomepageFilter;
