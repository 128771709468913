import style from "./MayorCard.module.css";
import RealisationLine from "../line/RealisationLine";
import { Link } from "react-router-dom";
import { generatePath } from "../../utils/helper";
import { useCallback, useEffect, useState } from "react";
import { FacebookIcon, FacebookShareButton } from "react-share";
import { Helmet } from "react-helmet";

function MayorCard({ data, realizationData, changeMandate, other = [] }) {
  const [offset, setOffset] = useState(false);

  const updateOffset = () => {
    // console.log("R01", window.scrollY);
    if (window.scrollY > 0) {
      setOffset(true);
    } else setOffset(false);
  };

  // THIS ONE WAS NECESSARY FOR JUMPY BEHAVIOUR :)))
  function debounce(func, delay) {
    let timeoutId;
    return function () {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(func, delay);
    };
  }
  const debouncedUpdateOffset = debounce(updateOffset, 10); // Adjust the delay as needed
  // The scroll listener
  useEffect(() => {
    window.addEventListener("scroll", debouncedUpdateOffset, { passive: true });
    return () => window.removeEventListener("scroll", updateOffset);
  }, []);

  return (
    <div id="mayorCardMain" className={`${style.mayorCard} ${offset && style.mayorScroll}`}>
      <Helmet>
        <title>{`Letërnjoftimi i Komunave - ${data?.fullname}`}</title>
        <link rel="canonical" href="https://www.komunat.institutigap.org/" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Letërnjoftimi i Komunave - ${data?.fullname}`} />
        <meta property="og:description" content={`Premtimet e kryetarit ${data?.fullname} të komunës ${data?.municipality}`} />
        <meta property="og:image" content={data?.img} />
        <meta property="fb:admins" content="598841857" />
        <meta property="fb:app_id" content="235475319917022" />
        <meta name="description" content={`Premtimet e kryetarit ${data?.fullname} të komunës ${data?.municipality}`} />
      </Helmet>
      <div className="flex">
        <div className={style.imageWrapper}>
          <img src={data?.img} alt={data?.fullname} />
        </div>
        <div className={style.mayorInfo}>
          <div className="spaceBetween">
            <div>
              <p>Mandati <span className={style.lowerCase}>{data.mandate}</span>{data.yearsServed && <span> ({data.yearsServed})</span>}</p>
              <h1 className="poppins-semibold">{data?.municipality} </h1>
              <h3>
                {data?.fullname}{" "}
                <span className="party">{data?.politicalParty}</span>
              </h3>
            </div>
            <FacebookShareButton
              // url={window.location.href}
              // url={"www.komunat.institutigap.org"}
              url={window.location.href}
              quote={`Letërnjoftimi i Komunave - ${data?.fullname}`}
              className={style.fbShareButton}
              media={
                "https://hips.hearstapps.com/hmg-prod/images/close-up-of-tulips-blooming-in-field-royalty-free-image-1584131603.jpg?crop=0.630xw:1.00xh;0.186xw,0&resize=1200:*"
              }
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>

          <div className={style.otherWrapper}>
            {other.length > 0 &&
              other.map((i, j) => {
                return (
                  <Link
                    key={j}
                    to={generatePath(`/promises/${i?.mayorId}`)}
                    className={`${style.otherImages} ${
                      i.mayorId === data.mayorId && style.otherActive
                    }`}
                  >
                    <img src={i.img} alt={i.fullname} />
                  </Link>
                );
              })}
          </div>
        </div>
      </div>
      <div className={style.mayorRealisation}>
        <RealisationLine
          data={realizationData}
          showLegend={!offset}
          showTotal={offset}
        />
      </div>
    </div>
  );
}
export default MayorCard;
