import { useEffect, useState } from "react";
import style from "./Categories.module.css";
import Icons from "../../components/icons/Icons";
import { BiCategoryAlt } from "react-icons/bi";

function Categories({ data, formattedLastUpdate }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const isMobile = width <= 1000;

  const scroll = (category) => {
    setOpenMenu(false);
    const element = document.getElementById(category);
    let mayorCardAndHeaderMenuHeight = document.getElementById('mayorCardMain').clientHeight + document.getElementById('headerMenu').clientHeight;
    const y = element.getBoundingClientRect().top + window.scrollY - (mayorCardAndHeaderMenuHeight + (isMobile ? (70):(11)));
    window.scrollTo({top: y, behavior: 'smooth'});
  };

  const iconClick = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <>
      {isMobile ? (
        <div className={style.mobList}>
          <div>
            <Icons
              icon={<BiCategoryAlt size={25} color="#19315a" />}
              iconClick={iconClick}
            />
          </div>
          {openMenu && (
            <ul className={style.list}>
            <span className={style.formattedLastUpdate}>Përditësuar {formattedLastUpdate}</span>
              {data.map((cat, k) => (
                <li key={k} value={cat.category} onClick={() => scroll(cat.category)}>
                  {cat.category} <span>{cat.promises.length}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      ) : (
        <ul className={style.list}>
          <span className={style.formattedLastUpdate}>Përditësuar {formattedLastUpdate}</span>
          {data.map((cat, k) => (
            <li key={k} value={cat.category} onClick={() => scroll(cat.category)}>
              {cat.category} <span>{cat.promises.length}</span>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
export default Categories;
