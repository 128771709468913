import { useMemo, useState } from "react";
import Grid from "../../layouts/grid/Grid";
import style from "./RealisationLine.module.css";

function RealisationLine({ data, showLegend, showTotal }) {
  const calculateTotal = (data) => {
    let sum = 0;
    data?.forEach((value) => {
      sum += parseInt(value.subTotal);
    });
    return sum;
  };

  const totalPromises = useMemo(() => calculateTotal(data), [data]);

  const calculatePercentage = (value) => {
    return (100 * parseInt(value)) / totalPromises;
  };

  return data ? (
    <div className={`${style.realisation} ${showLegend && style.thickLabel}`}>
      {showLegend && (
        <p>
          <span className={style.emp}>
            {totalPromises.toLocaleString()}
          </span> premtime në total
        </p>
      )}
      <div className={style.realisationWrapper}>
        {data ? (
          data.map((p, k) => (
            <label
              key={k}
              value={p.subTotal}
              className={p.cssClass}
              style={{ width: calculatePercentage(p.subTotal) + "%" }}
              title={p.titlePlural + ": " + p.subTotal}
            ></label>
          ))
        ) : (
          <>
            <label className={style.finished} style={{ width: "0%" }}></label>
            <label className={style.half} style={{ width: "0%" }}></label>
            <label className={style.started} style={{ width: "0%" }}></label>
            <label
              className={style.notStarted}
              style={{ width: "100%" }}
            ></label>
          </>
        )}
      </div>
      {showLegend && (
        <div className={style.legendInfo}>
          <Grid no="Bottom">
            {data &&
              data.map((p, k) => (
                <div className={"border" + p.cssClass} key={k}>
                  <span>{p.subTotal}</span> {p.titlePlural}
                </div>
              ))}
          </Grid>
        </div>
      )}
      {showTotal && (
        <p className={style.total}>
          <span>{totalPromises}</span> premtime
        </p>
      )}
    </div>
  ) : (
    <p>Nuk ka të dhëna për mandatin e zgjedhur</p>
  );
}
export default RealisationLine;
