import Icons from "../icons/Icons";
import style from "./Text.module.css";

function Text({ icon, title, desc, onClick, customize }) {
  return (
    <div className={`iconText ${style?.[customize]}`}>
      <div className="icon">
        <Icons icon={icon} iconClick={onClick} />
      </div>
      <div>
        <h1>{title}</h1>
        <p>{desc}</p>
      </div>
    </div>
  );
}
export default Text;
