import { Link } from "react-router-dom";

function Promises() {
  return (
    <div className="container">
      <h1>Pormises</h1>
      <Link to="/promises/1">View Promises Details</Link>
    </div>
  );
}
export default Promises;
