import Grid from "../grid/Grid";
import { Link, NavLink } from "react-router-dom";
import { MdHexagon } from "react-icons/md";
import style from "./header.module.css";
import { generatePath } from "../../utils/helper";

function DesktopHeader(props) {
  return (
    <header id="headerMenu">
      <div className="midContainer">
        <Link to={generatePath("")} className={style.headerLogo}>
          <img
            src={`/images/GAP_logo.png`}
            alt="logo"
          />
          Letërnjoftimi i Komunave
        </Link>
        <div className={style.header}>
          <div className={style.menuHeader}>
            <ul>
              {props.menu.map((i, j) => {
                return (
                  <li key={j}>
                    <NavLink
                      to={generatePath(i.url)}
                      className={({ isActive, isPending }) =>
                        isActive ? style.active : ""
                      }
                    >
                      {i.title}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
          <a
            href="https://institutigap.org/transparenca-buxhetore"
            className={style.customHeaderLogo}
          >
            <span>Transparenca Buxhetore e Komunave</span>
          </a>
        </div>
      </div>
     
    </header>
  );
}

export default DesktopHeader;
