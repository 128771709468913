import loader from "../../utils/lotties/loading.json";
import circle from "../../utils/lotties/circle.json";

import { useLottie } from "lottie-react";
import style from "./Loader.module.css";

function Loader({ defaultLoader = true }) {
  const loaderOptions = {
    animationData: defaultLoader ? loader : circle,
    loop: true,
  };
  const { View } = useLottie(loaderOptions);

  return (
    <div className={defaultLoader ? style.loaderWrapper :  style.circleWrapper}>
      <div>{View}</div>
    </div>
  );
}
export default Loader;
