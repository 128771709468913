// import "./var.module.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import AllRoutes from "./route/index";
import HeaderHandler from "./layouts/header/HeaderHandler";
import ReactGA from 'react-ga';

function App() {

  const TRACKING_ID = "G-TX7NN6BPB4";
  ReactGA.initialize(TRACKING_ID);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <HeaderHandler />
      <div className="body">
        <AllRoutes />
      </div>
    </>
  );
}
export default App;
