import React, { useState, useEffect, useRef } from "react";
import { BiLink } from "react-icons/bi";
import { client } from "../../utils/config.js";
import Grid from "../../layouts/grid/Grid";
import Categories from "../../components/list/Categories";
import PromiseCard from "../../components/card/PromiseCard";
import style from "./PromisesDetails.module.css";
import Loader from "../../components/loader/Loader.js";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";
import MayorCard from "../../components/card/MayorCard.js";
import { useParams } from "react-router-dom";

function Promises() {
  let { id } = useParams();
  const resultRef = useRef(null);

  const [detailsInfo, setDetailsInfo] = useState({
    loading: true,
    realizationLine: [],
    categories: [],
    promiseDetails: [],
    municipalityMayors: [],
    selectedPromise: -1,
    mayorData: {},
  });

  useEffect(() => {
    setDetailsInfo((prevState) => ({
      ...prevState,
      loading: true,
    }));
    getPageInfo(id);
  }, [id]);

  useEffect(() => {
    if (detailsInfo.selectedPromise !== -1) {
      //Fetch details
      client
        .get(`getPromiseDetails/${detailsInfo.selectedPromise}`)
        .then((r) => {
          // console.log("promiseDetails", r.data);
          setDetailsInfo((prevState) => ({
            ...prevState,
            promiseDetails: r.data,
          }));
        });
    }
  }, [detailsInfo.selectedPromise]);

  const getPageInfo = (mandate) => {
    client.get(`getMayorPromises/${mandate}`).then((response) => {
      //Get Other mayors info
      client
        .get(
          `getMunicipalityMayors/${response.data.mayorInfo[0].municipalityId}`
        )
        .then((res) => {
          //Get Mayor Data
          setDetailsInfo((prevState) => ({
            ...prevState,
            loading: false,
            realizationData: response.data.realisationLine,
            categories: response.data.details,
            mayorData: response.data.mayorInfo[0],
            municipalityMayors: res.data,
          }));
        });
    });
  };

  const showPromiseDetails = (id) => {
    setDetailsInfo((prevState) => ({
      ...prevState,
      selectedPromise: id === detailsInfo.selectedPromise ? -1 : id,
    }));
  };

  const scroll = (id) => {
    document.querySelector(`#${id}`).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="container pt20">
      {detailsInfo.loading && <Loader />}
      {detailsInfo.categories.length > 0 ? (
        <Grid no={3}>
          <Categories data={detailsInfo.categories} formattedLastUpdate={detailsInfo.mayorData.formattedLastUpdate} refs={resultRef} />
          <div className="relative">
            <MayorCard
              realizationData={detailsInfo.realizationData}
              data={detailsInfo.mayorData}
              other={detailsInfo.municipalityMayors}
            />
            <div className={style.detailsWrapper}>
              {detailsInfo.categories.map((category, categoryKey) => (
                <div
                  key={categoryKey}
                  className={style.catAndPromiseWrapper}
                  id={category.category}
                >
                  <div
                    id={category.category}
                    className={style.detailsHeader}
                    // ref={category.category}
                  >
                    <h2>{category.category}</h2>
                    <span>{category?.promises?.length} Premtime</span>
                  </div>
                  {/* <>{console.log(category.promises)}</> */}
                  {category.promises.map((p, k) => (
                    <div key={k} value={p.promiseId}>
                      <PromiseCard
                        test={k}
                        detailsInfo={detailsInfo}
                        showDetails={showPromiseDetails}
                        data={{
                          description: p.description,
                          realisation: p.realisation,
                          cssClass: p.cssClass,
                          id: p.promiseId,
                        }}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </Grid>
      ) : (
        !detailsInfo.loading && (
          <div className="midContainer">
            <div>
              <MayorCard
                realizationData={detailsInfo.realizationData}
                data={detailsInfo.mayorData}
                other={detailsInfo.municipalityMayors}
              />
              <p className={style.zeroPromise}>
                Në ketë komunë nuk kemi arritur të listojmë premtime zgjedhore,
                për shkak se kandidatet në zgjedhjet lokale të fundit nuk kanë
                zhvilluar fushatë aktive në terren dhe nuk kanë pasur programe
                zgjedhore.
              </p>
              <p className={style.zeroPromise}>
                "U ovoj opštini nismo mogli da prikažemo predizborna obecanja,
                zbog toga što kandidati na poslednjim lokalnim izborima nisu
                sprovodili predizbornu kampanju na terenu tako da nisu imali
                svoje izborne programe."
              </p>
            </div>
          </div>
        )
      )}
    </div>
  );
}
export default Promises;
