import Grid from "../../layouts/grid/Grid";
import style from "./Infographics.module.css";
import WhiteCard from "../../components/card/WhiteCard";
import { useEffect, useState } from "react";
import { client } from "../../utils/config";
import ContentLoad from "../../components/loader/ContentLoader";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Loader from "../../components/loader/Loader";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
function Infographics() {
  const [activeImage, setActiveImage] = useState({
    showLightbox: false,
    images: [],
    index: 0,
  });

  const [data, setData] = useState({
    years: [],
    documents: [],
    isLoading: true,
    selectedYear: -1,
  });

  const changeDataProps = (data) => {
    setData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  useEffect(() => {
    getInfographicsData();
  }, []);

  useEffect(() => {
    getInfographicsData();
  }, [data.selectedYear]);

  
  useEffect(() => {
  }, [activeImage]);

  const getInfographicsData = () => {
    changeDataProps({
      isLoading: true,
    });
    client.get(`getInfographics/${data.selectedYear}`).then((response) => {
      // console.log("RES", response);

      if (data.selectedYear === -1) {
        changeDataProps({
          years: response.data.year,
          documents: response.data.data,
          isLoading: false,
        });
      } else {
        changeDataProps({
          documents: response.data.data,
          isLoading: false,
        });
      }
    });
  };



  return (
    <>
      {data.isLoading && <Loader />}
      <div className="midContainer">
        <div className={style.reportWrapper}>
          <ul>
            {data.years &&
              data?.years.map((i, j) => {
                return (
                  <li
                    className={`${
                      i.year === data.selectedYear && style.active
                    }`}
                    key={j}
                    onClick={() => changeDataProps({ selectedYear: i.year })}
                  >
                    {i.year}
                  </li>
                );
              })}
          </ul>
          <div className={style.body}>
            <Grid no={2}>
              {data?.documents.map((i, j) => {
                return (
                  <div
                    className={style.card}
                    onClick={() =>
                      setActiveImage({ showLightbox: true, images: i.images, index: 0 })
                    }
                  >
                    <WhiteCard data={i} key={j} />
                  </div>
                );
              })}
            </Grid>
          </div>
          {activeImage.showLightbox && (
            <Lightbox

            mainSrc={activeImage.images[activeImage.index]}
            nextSrc={activeImage.images[(activeImage.index + 1) % activeImage.images.length]}
            prevSrc={activeImage.images[(activeImage.index - 1) % activeImage.images.length]}
            onCloseRequest={() => setActiveImage({showLightbox: false, images: [],  index: 0})}
            onMovePrevRequest={() =>
              setActiveImage((prevState) => ({
                ...prevState,
                ...{
                  index: (activeImage.index - 1) % activeImage.images.length,
                },
              }))
            }
            onMoveNextRequest={() =>
              setActiveImage((prevState) => ({
                ...prevState,
                ...{
                  index: (activeImage.index + 1) % activeImage.images.length,
                },
              }))
              }
            />
          )}
        </div>
      </div>
    </>
  );
}
export default Infographics;
