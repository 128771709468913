import { RiCloseLine } from "react-icons/ri";
import PromiseField from "../../components/card/PromiseField";
import Icons from "../../components/icons/Icons";
import Grid from "../grid/Grid";
import { useEffect, useState } from "react";
import { client } from "../../utils/config";
import Dropdown from "../../components/dropdown/Dropdown";

function PromiseFields({ showMap, dataProps, party, changeDataProps }) {
  const [promisesCategories, setPromisesCategories] = useState([]);

  useEffect(() => {
    getPromisesCategory();
  }, []);

  useEffect(() => {
    getPromisesCategory();
  }, [dataProps.selectedMandate, dataProps.selectedParty]);

  const getPromisesCategory = () => {
    client
      .get(
        `getPromisesCategory/${dataProps.selectedMandate}/${dataProps.selectedParty}`
      )
      .then((response) => {
        let categoriesData = [];

        response.data.map((i, j) => {
          let title = i.title.trim();

          if (!categoriesData[title]) {
            categoriesData[title] = [];
          }

          if (!categoriesData[title]["total"])
            categoriesData[title]["total"] = parseInt(i["subTotal"]);
          else categoriesData[title]["total"] += parseInt(i["subTotal"]);

          categoriesData[title]["image"] = i["categoryImage"];

          if (!categoriesData[title]["percentage"]) {
            categoriesData[title]["percentage"] = [];
          }
          categoriesData[title]["percentage"].push({
            subTotal: i["subTotal"],
            titlePlural: i["titlePlural"],
            cssClass: i["cssClass"],
          });
        });
        setPromisesCategories(categoriesData);
      });
  };

  const iconClick = () => {
    showMap(true);
  };

  const onClick = (value) => {
    changeDataProps({
      selectedParty: value.politicalPartyId,
      selectedPartyName: value.name,
      selectedMunicipality: -1,
    });
  };

  return (
    <>
      <div className="spaceBetween">
        <div className="flex">
          <div className="iconText">
            <div className="icon">
              <Icons
                icon={<RiCloseLine size={25} color="#19315a" />}
                iconClick={iconClick}
              />
            </div>
          </div>
          {dataProps.selectedParty > -1 ? (
            <div>
              <p>Premtimet e kryetarëve të</p>
              <h1>{dataProps.selectedPartyName}</h1>
            </div>
          ) : (
            <div>
              <p>Premtimet e dhëna</p>
              <h1>sipas fushave</h1>
            </div>
          )}
        </div>
        <div>
          <div>
            <Dropdown
              data={party}
              changeField={onClick}
              active={
                dataProps?.selectedParty > -1
                  ? dataProps.selectedPartyName
                  : "Filtro sipas partisë politike"
              }
              title={"Mandati"}
            />
          </div>
        </div>
      </div>
      <div className="ptb20">
        {promisesCategories &&
          (Object.keys(promisesCategories).length ? (
            <Grid no={4}>
              {Object.entries(promisesCategories)?.map(([key, value], j) => {
                return <PromiseField key={j} data={value} title={key} />;
              })}
            </Grid>
          ) : (
            <h1 className="noPromise">Nuk u gjet asnjë premtim!</h1>
          ))}
      </div>
    </>
  );
}
export default PromiseFields;
